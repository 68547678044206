<template>
  <div class="rdv" v-if="markTimes">
    <div class="hours">
      <div v-for="hr in markTimes" :key="hr" class="time-remark">{{ hr }}</div>
    </div>
    <div class="time-lines">
      <week-day-line
        v-for="wd in wkDayTimes"
        :key="wd.label"
        :label="wd.label"
        :active="wd.active"
        :sections="wd.sections"
        :sched="markTimes"
        @changeactive="(val) => (wd.active = val)"
        class="weekday-line"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import WeekDayLine from './WeekDayLine.vue';
import { SCHEDULE_TIME_MILESTONES } from '../../store/constant';
import axios from '@/plugins/axios';
import { GET_USERDATA } from '@/store/types';
import { useStore } from 'vuex';
import { defaultValues } from '@/store/data/schedule';
export default {
  components: {
    WeekDayLine
  },
  props: {
    weekDayTimes: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const wkDayTimes = ref([]);
    //wkDayTimes.value = props.weekDayTimes;
    const markTimes = ref(null);
    const store = useStore();
    const userData = computed(() => store.getters[GET_USERDATA]);
    const timeline = [...SCHEDULE_TIME_MILESTONES];
    getFirstAndLastHours();

    const defaults = defaultValues();
    const days = {
      Mon: 0,
      Tue: 1,
      Wed: 2,
      Thu: 3,
      Fri: 4,
      Sat: 5,
      Sun: 6
    };
    let firstDay = defaults.first_day;
    let lastDay = defaults.last_day;

    async function getFirstAndLastHours() {
      await axios.get(`setting?owner_id=${userData.value.id}`).then((res) => {
        const data = res.data.data.items;
        let first = data.filter((el) => el.type == 'first_hour')[0];
        let last = data.filter((el) => el.type == 'last_hour')[0];
        firstDay = data.filter((el) => el.type == 'first_day')[0];
        lastDay = data.filter((el) => el.type == 'last_day')[0];
        first = first ? first.value : defaults.first_hour;
        last = last ? last.value : defaults.last_hour;
        firstDay = firstDay ? firstDay.value : defaults.first_day;
        lastDay = lastDay ? lastDay.value : defaults.last_day;
        wkDayTimes.value = [];
        let started = false;
        let finished = false;
        for (const day of props.weekDayTimes) {
          if (finished) {
            day.active = false;
            continue;
          }
          if (!started && days[day.label] != firstDay) {
            day.active = false;
            continue;
          }
          started = true;
          wkDayTimes.value.push(day);
          if (days[day.label] == lastDay) {
            finished = true;
          }
        }

        let index = checkIndex(first);
        timeline.splice(0, index);

        index = checkIndex(last);
        timeline.splice(index + 1, timeline.length - 1);

        markTimes.value = timeline;
      });
    }
    function checkIndex(value) {
      return timeline.indexOf(valueToTimeString(value));
    }

    function valueToTimeString(value) {
      return value < 10 ? `0${value}:00` : `${value}:00`;
    }
    watch(
      () => [props.weekDayTimes],
      () => {
        if (wkDayTimes.value.length > 0) return;
        let started = false;
        let finished = false;
        for (const day of props.weekDayTimes) {
          if (finished) {
            day.active = false;
            continue;
          }
          if (!started && days[day.label] != firstDay) {
            day.active = false;
            continue;
          }
          started = true;
          wkDayTimes.value.push(day);
          if (days[day.label] == lastDay) {
            finished = true;
          }
        }
        props.weekDayTimes = wkDayTimes.value;
      }
    );
    return { markTimes, wkDayTimes };
  }
};
</script>

<style scoped>
.rdv {
  position: relative;
}
.hours {
  display: flex;
  justify-content: space-between;
  margin-left: 170px;
}
.time-remark {
  display: flex;
  justify-content: center;
  width: 60px;
}
.time-remark:after {
  content: '';
  position: absolute;
  width: 1px;
  /* background: #f5f5f5; */
  background: #cccccc;
  height: 100%;
  top: 25px;
}
.time-lines {
  display: block;
}
.weekday-line {
  padding-top: 50px;
}
</style>
