<template>
  <div class="weekday-line">
    <div class="active-btn">
      <div class="toggle">
        <toggle-button :value="active" @onchange="changeActive" />
        <p class="ml-1">{{ label }}</p>
      </div>
      <div class="btns">
        <Button
          icon="pi pi-plus-circle"
          class="p-mr-1"
          :disabled="sectionsRef[0].workPeriods.length === 3"
          @click="addWP"
        />
        <Button
          icon="pi pi-minus-circle"
          :disabled="sectionsRef[0].workPeriods.length === 0"
          @click="deleteWP"
        />
      </div>
    </div>
    <div class="time-liner">
      <div v-if="active">
        <div
          v-for="(st, idx) in sectionsRef"
          :key="idx"
          :style="`width: ${sectionWidth(st)}%; left: ${sectionLeft(st)}%`"
          class="available-section"
          @drop="
            (e) => {
              e.preventDefault();
            }
          "
          @dragover="
            (e) => {
              e.preventDefault();
            }
          "
        >
          <work-period
            v-for="(wp, idx) in st.workPeriods"
            :key="idx"
            :startTime="wp.startTime"
            :endTime="wp.endTime"
            :style="`width: ${sliderWidth(st, wp, idx)}%; left: ${sliderLeft(
              st,
              wp,
              idx
            )}%`"
            class="time-slider"
            @movelefthandler="(e) => onMoveLeftHandler(e, st, wp)"
            @moverighthandler="(e) => onMoveRightHandler(e, st, wp, idx)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { durationMinutes } from '../../plugins/utility';
import WorkPeriod from './WorkPeriod.vue';
import ToggleButton from '../UI/ToggleButton';
export default {
  props: {
    label: {
      type: String,
      default: 'Mon'
    },
    active: {
      type: Boolean,
      default: false
    },
    sections: {
      type: Array,
      default: () => []
    },
    sched: {
      type: Array
    }
  },
  components: {
    WorkPeriod,
    ToggleButton
  },
  setup(props, { emit }) {
    const sectionsRef = ref();
    sectionsRef.value = props.sections;
    const schedule = ref(props.sched);
    const openTime = schedule.value[0];
    const scheduleLength = schedule.value.length;
    const closeTime = schedule.value[scheduleLength - 1];
    watch(
      () => [props.sections],
      () => {
        sectionsRef.value = props.sections;
      }
    );

    errorRemoval();

    function errorRemoval() {
      const data = sectionsRef.value[0].workPeriods;
      data.forEach((el, index) => {
        if (el.startTime < openTime || el.startTime > closeTime) {
          data.splice(index, 1);
        }
      });
    }

    function sectionWidth(st) {
      return 100;
    }

    function sectionLeft(st) {
      return (durationMinutes('00:00', 0) / 1440) * 100;
    }

    function sliderWidth(st, wp, idx) {
      const startTime = wp.startTime;
      const endTime = wp.endTime;
      return (
        (durationMinutes(startTime, endTime) /
          durationMinutes(openTime, closeTime)) *
        100
      );
    }
    function overlapLine(st, wp, idx) {
      if (st.workPeriods.length > 1) {
        let rightPoint = parseInt(wp.endTime.split(':00')[0]);
        st.workPeriods.forEach((el, index) => {
          if (index !== idx && idx < index) {
            const item = parseInt(el.startTime);
            if (rightPoint >= item) {
              deleteWP(index);
            }
          }
        });
      }
    }
    function sliderLeft(st, wp, idx) {
      const startTime = wp.startTime;

      return (
        (durationMinutes(openTime, startTime) /
          durationMinutes(openTime, closeTime)) *
        100
      );
    }

    function changeActive(val) {
      emit('changeactive', val);
    }

    function addWP() {
      let startTime = schedule.value[1];
      let endTime = schedule.value[2];
      let spIdx = 0;
      let epIdx = 0;
      for (const period of sectionsRef.value[0].workPeriods) {
        if (schedule.value.indexOf(period.startTime) > spIdx) {
          spIdx = schedule.value.indexOf(period.startTime);
          epIdx = schedule.value.indexOf(period.endTime);
          if (epIdx > schedule.value.length - 3) {
            return;
          }
          startTime = schedule.value[epIdx + 1];
          const intervalCnt =
            schedule.value.length - 1 - epIdx - 1 > 4
              ? 4
              : schedule.value.length - 1 - epIdx - 1;
          endTime = schedule.value[epIdx + 1 + intervalCnt];
        }
      }
      sectionsRef.value[0].workPeriods.push({
        startTime,
        endTime
      });
    }

    function deleteWP(ind) {
      const length = sectionsRef.value[0].workPeriods.length;
      if (length > 0) {
        const index = ind ? ind : length - 1;
        sectionsRef.value[0].workPeriods.splice(index, 1);
      }
    }

    function onMoveLeftHandler(e, st, wp) {
      const wpNode = e.target.parentNode;
      const availableSectionNode = wpNode.parentNode;
      const sectionRect = availableSectionNode.getBoundingClientRect();
      const pointerPosition = e.clientX;
      const relativePosition =
        (pointerPosition - sectionRect.x) / sectionRect.width;
      const currentHandlerPosition =
        schedule.value.indexOf(wp.startTime) / scheduleLength;
      const remarkIdx =
        currentHandlerPosition > relativePosition
          ? Math.ceil(relativePosition * scheduleLength)
          : Math.floor(relativePosition * scheduleLength);

      const endPoints = getNearestPoints(st, wp);

      if (
        remarkIdx > -1 &&
        remarkIdx < schedule.value.indexOf(wp.endTime) &&
        remarkIdx > endPoints.nearestEndPointIdx &&
        wp.startTime !== schedule.value[remarkIdx]
      ) {
        wp.startTime = schedule.value[remarkIdx];
      }
    }

    function onMoveRightHandler(e, st, wp, idx) {
      const wpNode = e.target.parentNode;
      const availableSectionNode = wpNode.parentNode;
      const sectionRect = availableSectionNode.getBoundingClientRect();
      const pointerPosition = e.clientX;
      const relativePosition =
        (pointerPosition - sectionRect.x) / sectionRect.width;
      const currentHandlerPosition =
        schedule.value.indexOf(wp.endTime) / scheduleLength;
      const remarkIdx =
        currentHandlerPosition > relativePosition
          ? Math.ceil(relativePosition * scheduleLength)
          : Math.floor(relativePosition * scheduleLength);

      const endPoints = getNearestPoints(st, wp);
      if (
        remarkIdx > -1 &&
        remarkIdx > schedule.value.indexOf(wp.startTime) &&
        remarkIdx < endPoints.nearestStartPointIdx &&
        wp.endTime !== schedule.value[remarkIdx]
      ) {
        wp.endTime = schedule.value[remarkIdx];
      }
      overlapLine(st, wp, idx);
    }

    function getNearestPoints(st, wp) {
      let nearestStartPointIdx = schedule.value.length;
      let nearestEndPointIdx = -1;
      for (const period of st.workPeriods) {
        const epIdx = schedule.value.indexOf(period.endTime);
        if (
          epIdx < schedule.value.indexOf(wp.startTime) &&
          epIdx > nearestEndPointIdx
        ) {
          nearestEndPointIdx = epIdx;
        }
        const spIdx = schedule.value.indexOf(period.endTime);
        if (
          spIdx > schedule.value.indexOf(wp.endTime) &&
          spIdx < nearestStartPointIdx
        ) {
          nearestStartPointIdx = spIdx;
        }
      }

      return { nearestStartPointIdx, nearestEndPointIdx };
    }

    return {
      sectionsRef,
      sectionWidth,
      sectionLeft,
      sliderWidth,
      sliderLeft,
      changeActive,
      addWP,
      deleteWP,
      onMoveLeftHandler,
      onMoveRightHandler
    };
  }
};
</script>

<style lang="scss" scoped>
.weekday-line {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.active-btn {
  display: flex;
  align-items: center;
  width: 180px;
  justify-content: space-between;
  .toggle {
    display: flex;
    align-items: center;
  }
  button {
    width: 30px;
    &:disabled {
      background: #999999;
      cursor: not-allowed;
    }
  }
}
.time-liner {
  position: absolute;
  width: calc(100% - 150px - 80px);
  /* background: #fbfbfb; */
  background: #cccccc;
  height: 10px;
  border: 1px solid #f5f5f5;
  z-index: 9;
  left: 200px;
}
.available-section {
  display: block;
  position: absolute;
  background: #c1ebfb;
  height: 8px;
}
.time-slider {
  position: absolute;
}
</style>
