<template>
  <div class="time-slider">
    <div class="slider-handler left" @drag="onDragLeft" draggable="true">
      <div class="slider-handler-label">{{ startTime }}</div>
    </div>
    <div class="slider-handler right" @drag="onDragRight" draggable="true">
      <div class="slider-handler-label">{{ endTime }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    duration: {
      type: Number,
      default: 1
    },
    startTime: {
      type: String,
      default: '09:00'
    },
    endTime: {
      type: String,
      default: '18:00'
    },
  },
  setup(props, { emit }) {
    function onDragLeft(event) {
      emit('movelefthandler', event);
    }

    function onDragRight(event) {
      emit('moverighthandler', event);
    }

    return { onDragLeft, onDragRight };
  }
};
</script>

<style scoped>
.time-slider {
  height: 8px;
  background: #06adef;
  position: relative;
  display: flex;
  align-items: center;
}
.slider-handler {
  background: inherit;
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  width: 19px;
  height: 19px;
  border: 0;
  text-decoration: none;
  outline: none;
  position: absolute;
  z-index: 999;
  /* cursor: grab; */
}
/* .slider-handler:active {
  cursor: grabbing;
} */
.slider-handler.left {
  left: -9px;
}
.slider-handler.right {
  right: -9px;
}
.slider-handler-label {
  display: block;
  color: #6a6e77;
  background: #fff;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.3));
  width: 60px;
  line-height: 28px;
  font-size: 12px;
  border-radius: 1000px;
  text-align: center;
  position: absolute;
  top: -40px;
  margin-left: 0;
}
.left .slider-handler-label {
  left: -5px;
}
.right .slider-handler-label {
  right: -5px;
}
.slider-handler-label:after {
  margin: 0 auto;
  bottom: -8px;
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 8px solid #fff;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
.left .slider-handler-label:after {
  left: 10px;
}
.right .slider-handler-label:after {
  right: 10px;
}
</style>
