<template>
  <div class="toggle-button">
    <label class="switch">
      <input type="checkbox" v-model="checked" @change="onChange" />
      <span class="slider round"></span>
    </label>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const checked = ref();
    checked.value = props.value;
    function onChange() {
      emit('onchange', checked.value);
    }

    return { checked, onChange };
  }
};
</script>

<style scoped>
.toggle-button {
  width: fit-content;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #06adef;
}
input:focus + .slider {
  box-shadow: 0 0 1px #06adef;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
